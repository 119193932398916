<template>
  <div>
    <div class="d-flex flex-column-fluid">
      <!--begin::Container-->
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <!--begin::Card-->
            <div class="card card-custom gutter-b example example-compact">
              <div class="card-header">
                <h3 v-if="$route.meta.mode !== 'update'" class="card-title">
                  Ajouter une ville
                </h3>
                <h3 v-if="$route.meta.mode === 'update'" class="card-title">
                  Modifier une ville
                </h3>
              </div>
              <!--begin::Form-->
              <form class="form" @submit.prevent="save">
                <div class="card-body">
                  <div class="form-group row">
                    <div class="col-lg-6">
                      <label>Nom:</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="form.nom"
                        :class="{ 'is-invalid': errors.nom }"
                      />
                      <span class="form-text text-danger" v-if="errors.nom">{{
                        errors.nom[0]
                      }}</span>
                    </div>
                    <div class="col-lg-3">
                      <label>Populaire ?</label>
                      <div class="radio-inline">
                        <label class="radio radio-solid">
                          <input
                            type="radio"
                            name="beta"
                            v-model="form.populaire"
                            value="true"
                          />
                          <span></span>OUI</label
                        >
                        <label class="radio radio-solid">
                          <input
                            type="radio"
                            name="beta"
                            v-model="form.populaire"
                            value="false"
                          />
                          <span></span>NON</label
                        >
                        <span
                          class="form-text text-danger"
                          v-if="errors.populaire"
                          >{{ errors.populaire[0] }}</span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="col-lg-6">
                      <label>Région:</label>
                      <multiselect
                        style="padding-top: 10px !important;"
                        v-model="form.region"
                        :options="option.regions"
                        :multiple="false"
                        :close-on-select="true"
                        :clear-on-select="true"
                        :hide-selected="true"
                        placeholder="Choisir une région"
                        label="nom"
                        track-by="id"
                        select-label=""
                        deselect-label=""
                        @input="setRegionId()"
                        :class="{ 'is-invalid': errors.region_id }"
                      ></multiselect>
                      <span
                        class="form-text text-danger"
                        v-if="errors.region_id"
                        >{{ errors.region_id[0] }}</span
                      >
                    </div>
                    <div class="col-lg-6">
                      <label
                        >Couverture image de la ville
                        <input
                          type="file"
                          @change="handleonchange"
                          name="ville_cover_image"
                          accept="image/*"
                          class="form-control"
                        />
                        <span
                          class="form-text text-danger"
                          v-if="errors.ville_cover_image"
                          >{{ errors.ville_cover_image[0] }}</span
                        >
                      </label>
                    </div>
                  </div>
                </div>
                <div class="card-footer">
                  <div class="row">
                    <div class="col-lg-12 text-right">
                      <button
                        type="submit"
                        ref="kt_login_signin_submit"
                        class="btn btn-primary mr-2"
                      >
                        Enregistrer
                      </button>
                      <button type="reset" class="btn btn-secondary">
                        Annuler
                      </button>
                    </div>
                  </div>
                </div>
              </form>
              <!--end::Form-->
            </div>
            <!--end::Card-->
          </div>
        </div>
      </div>
      <!--end::Container-->
    </div>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import Multiselect from "vue-multiselect";
import Swal from "sweetalert2";

export default {
  name: "Form",
  components: { Multiselect },
  data() {
    return {
      initialize: "town/create",
      store: "town",
      method: "post",
      form: {},
      option: {
        regions: []
      },
      photo: "",
      success: false,
      has_error: false,
      error: "",
      errors: {},
      images: []
    };
  },
  beforeMount() {
    if (this.$route.meta.mode === "update") {
      this.initialize = "town/" + this.$route.params.id + "/edit";
      this.store = "town/" + this.$route.params.id;
      this.method = "post";
    }
    this.fetchData();
  },
  methods: {
    fetchData() {
      let vm = this;
      ApiService.get(this.initialize).then(function(response) {
        vm.form = response.data.form;
        vm.option = response.data.option;
      });
    },
    setRegionId() {
      this.form.region_id = this.form.region.id;
    },

    handleonchange(e) {
      this.photo = e.target.files[0];
    },

    save() {
      let vm = this;
      let formData = new FormData();
      /*for (let i = 0; i < this.images.length; i++) {
        let file = vm.images[i];
        console.log(file);
        formData.append("ville_cover_image[" + i + "]", file);
      }*/
      let config = { headers: { "content-type": "multipart/form-data" } };
      Object.entries(vm.form).forEach(([key, value]) => {
        formData.set(key, value);
      });
      formData.append("ville_cover_image", this.photo);
      if (this.$route.meta.mode === "update") {
        formData.append("_method", "PUT");
      }
      console.log(formData);
      // set spinner to submit button
      const submitButton = this.$refs["kt_login_signin_submit"];
      submitButton.classList.add(
        "spinner",
        "spinner-light",
        "spinner-right",
        "disabled"
      );
      ApiService[this.method](this.store, formData, config)
        .then(function(response) {
          if (response.data.success) {
            if (vm.$route.meta.mode === "create") {
              Swal.fire({
                title: "Réussie",
                text: "La ville a été enrégistré avec succès.",
                icon: "success",
                showConfirmButton: false,
                timer: 2000,
                showClass: {
                  popup: "animate__animated animate__fadeInDown"
                },
                hideClass: {
                  popup: "animate__animated animate__fadeOutUp"
                }
              });
              vm.$router.push(vm.$route.meta.back);
            } else {
              Swal.fire({
                title: "Réussie",
                text: "La ville a été modifié avec succès.",
                icon: "success",
                showConfirmButton: false,
                timer: 2000,
                showClass: {
                  popup: "animate__animated animate__fadeInDown"
                },
                hideClass: {
                  popup: "animate__animated animate__fadeOutUp"
                }
              });
              vm.$router.push(vm.$route.meta.back);
            }
          }
        })
        .catch(function(error) {
          vm.errors = error.response.data.errors;
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right",
            "disabled"
          );
          Swal.fire({
            title: "Ooups...",
            text: "Veuillez corrigé les erreurs.",
            icon: "error",
            showConfirmButton: false,
            timer: 2000,
            showClass: {
              popup: "animate__animated animate__fadeInDown"
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp"
            }
          });
        });
    }
  }
};
</script>

<style scoped></style>
